<template>
    <div class="lay_container">
        <Header></Header>
        <div class="cont_m" @mousedown="handleonmousedown($event)">
            <div class="max_cont_width flex_box">
                <!-- <Menu></Menu> -->
                <div class="left_menu">
                    <ul class="de_menu_list">
                        <li class="de_menu_item" v-for="(item, index) in menuTitleList" :key="index" v-show="item.status == false">
                            <div class="flex_center_between_box" v-if="item.status == false" :class="{ noitclick: item.status == true }">
                                <span class="de_menu_item_a" :class="{ 'router-link-active': (linkActive == index && $route.name === item.title2.replace(/\s/g, '')) || ($route.name == 'ImportAndExport' && linkActive == index), noitclick: item.status == true.valueOf, import: index == '6--1' }" @click="handlFristMenu(index, item, -1), (linkActive = index)">
                                    <div class="title flex_center" :class="linkActive == index && linkActive != -1 ? 'subOn' : ''">
                                      {{ item.title }}
                                        <span class="el-icon-lock" style="font-size: 20px; font-weight: 600; position: absolute; left: 220px" v-if="!item.power && item.id != '7001'"></span>
                                    </div>
                                </span>
                                <div class="show_ic" @click="showSubMenu(item, index)" v-if="item.result.length != 0" :class="{ open: item.disBol == true, close: item.disBol == false }"></div>
                            </div>
                            <transition name="sub-comments">
                                <ul class="de_menu_sublist" v-show="item.disBol">
                                    <li class="de_menu_subitem" v-for="(subItem, i) in item.result" :key="i" v-show="subItem.status == false">
                                        <div v-if="subItem.status == false" :class="{ noitclickk: subItem.status == true }" @click="handlFristMenu(index, subItem, i)">
                                            <span class="de_menu_subitem_a" :class="{ 'router-link-active': subLinkActive == index + '-' + i }">
                                                {{ subItem.name }}
                                                <span class="el-icon-lock" style="font-size: 16px; font-weight: 600; position: absolute; left: 190px; top: 10px" v-if="!subItem.power"></span>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </transition>
                        </li>
                    </ul>
                </div>
                <div class="right_box">
                    <!-- <RightHeader @getIsTranslate="getIndexIsTranslate" :companyObj="companyBase"></RightHeader> -->
                    <div class="flex_center_between_box">
                        <div class="c_detail_top_left line_2">
                            <div v-if="indexIsTranslate" class="c_d_title">{{ $route.query.name }}</div>
                            <div v-if="!indexIsTranslate" class="c_d_title">{{ $route.query.name }}</div>
                            <div class="flex_between_box">
                                <div :class="{ c_d_tag: true }"  @click="tabshow($event)">
                                    <el-tag v-for="(item, i) in Wathchlist?.content_types" :key="i">
                                        <span>
                                            {{ item.description }}
                                            <i v-if="item.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span >ACTIVE</span></i>
                                            <i v-if="item.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span >INACTIVE</span></i>
                                        </span>
                                    </el-tag>

                                    <div  v-for="(item, index) in Wathchlist?.content_types" :key="'sub_content_type_' + index">
                                        <el-tag v-show="item.sub_content_types" v-for="(ite, ind) in item.sub_content_types" :key="'sub_content_type_' + index + '_sub_' + ind">
                                            <div class="linheight ">
                                                {{ ite.description }}
                                                <i v-if="ite.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span >ACTIVE</span></i>
                                                <i v-if="ite.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span >INACTIVE</span></i>
                                            </div>
                                        </el-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="c_detail_top_right">
                            <div class="c_d_buttom flex_box">
                                <!-- <el-popover placement="bottom" trigger="hover" width="240" popper-class="pop_link"> -->
                                <button class="btn_blue flex_center" slot="reference" disabled @click="compareCompanyCountry('Company')">
                                    <img src="../../assets/img/detail/btn_ic1.png" />
                                    Compare
                                </button>
                                <!-- <ul class="compare_box">
                                        <li @click="compareCompanyCountry('Company')">Company</li>
                                        <li @click="compareCompanyCountry('Country')">Country</li>
                                    </ul> -->
                                <!-- </el-popover> -->
                                <el-tooltip v-if="updateDisabled" content="It normally takes 2-4 days to deliver,this updated rerpot." :visible-arrow="false" placement="top" effect="light">
                                    <button class="btn_gray">In Production...</button>
                                </el-tooltip>
                                <button class="btn_blue flex_center" disabled v-else @click="getpdffsuo('offlineReport')">
                                    <img src="../../assets/img/detail/btn_ic2.png" />
                                    Update
                                </button>
                                <button disabled class="btn_blue flex_center">
                                    <img src="../../assets/img/detail/btn_ic3.png" />
                                    Report Error
                                </button>
                            </div>
                            <div class="c_d_icbtn">
                                <el-tooltip class="item" popper-class="tool_tip" :visible-arrow="false" effect="light" content="Translate" placement="bottom">
                                    <el-button class="tool_blue" @click="btnTranslate" disabled>
                                        <img id="fan_yi" src="../../assets/img/detail/head_ic1.png" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip class="item" popper-class="tool_tip tool_tip2" effect="light" :visible-arrow="false" content="Download Online Report" placement="bottom">
                                    <el-button disabled class="tool_blue" @click="getpdffsuo('onlineReport')">
                                        <img src="../../assets/img/detail/head_ic3.png" />
                                    </el-button>
                                </el-tooltip>
                                <el-tooltip class="item" popper-class="tool_tip tool_tip3" :visible-arrow="false" effect="light" content="Add to My List" placement="bottom">
                                    <el-button disabled :class="isfalow == true ? 'tool_add' : ''"><img src="../../assets/img/detail/head_ic2.png" /></el-button>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <router-view @updateSubLink="updateSubLinkParent"></router-view>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <!-- 收藏弹框 -->
        <Collection :fromPage="fromPage" @childCloseDialog="closeCollageDialog(arguments)" :addList.sync="addList" :addPop="addPopIndex" :collecObj="collecObj"></Collection>

        <!-- 报错弹框 -->
        <Reporterror :showReport="showReportIndex" :companynameen="companyBase?.nameEn" @closeReportDialog="closeDialog($event)" :powerStatus="powerToReportErrorStatus"></Reporterror>
        <!-- 锁 -->
        <Suo :suoClose="suoclose" :obj="xuanzeobj" @closeCloseDialog="clossuoDialog($event)" @closeCloseDialog1="clossuoDialog1($event)" :update="update" :powerStatus="powerToReportErrorStatus"></Suo>
    </div>
</template>
<script>
import { getCompanyDetailMenuList, ordersAdd, getIsCollect } from '@/api/companyApi.js';

import { searchDetailsById } from '@/api/searchApi.js';
import bus from '@/components/bus';
import Collection from '@/components/collection';
import Footer from '@/components/footer';
import Header from '@/components/header';
import Reporterror from '@/components/reportError';
import Suo from '@/components/suo';
import { mixins1 } from '@/mixins/index';

export default {
    components: { Header, Footer, Reporterror, Collection, Suo },
    mixins: [mixins1],
    // inject: ['reload'],
    data() {
        return {
            indexIsTranslate: false,
            isActive: true,
            loadig: true,
            highquality: false,
            Name: '',
            companyBase: {
                name: '',
                nameEn: '',
                aaaId: '',
                companyCountry: '',
                lastUpdate: '',
                // lableActive: '',
                // lableCode: '',
                // lableDate: '',
                tags: [],
            },
            //tags: ['Active', 'MNC', '2003'],
            // isTranslate: false,
            linkActive: sessionStorage.getItem('menuItem') ? sessionStorage.getItem('menuItem') : 0,
            subLinkActive: '',
            menuTitleList: [],
            updateDisabled: false,
            showReportIndex: false,
            suoclose: false,
            suopower: false,
            addPopIndex: false,
            fromPage: 'company',
            xuanzeobj: {},
            collecObj: {},
            addList: [],
            orderStatus: '',
            onlineOrderId: '',
            offlineOrderId: '',
            // powerResult: {},
            token: localStorage.getItem('token'),
            powerToPage: {}, //分发页面权限
            powerToReportErrorStatus: false,
            powerCurStatus: {},
            isfalow: false,
            update: {
                updat: false,
                PDFFile: '',
            },
            zhuce: {},
            zhuce1: {},
            sources: [],
            Wathchlist: [],
            images: [],
            timers: '',
            last_reviewed_date: '',
            watchlisrole: { primary: [] },
            hostrytable: [],
            lianshu: {},
            person: {},
            active_status: '',
            watchlisttable: [],
            watchlistothertable: [],
            parentTablenum1: [],
            Relationstable: [],
            addressable: [],
            tableList: [],
            str: '',
        };
    },
    created() {
        //父路由监听：
        bus.$on('toRightHeadObj', companyBase => {
            this.companyBase = companyBase;
        });
        // this.getMenuList();
        this.updateSubLinkParent();
    },
    mounted() {
        // window.addEventListener('setItem', this.listenMenuIndexChange);
        window.addEventListener('setItem', () => {
            // this.isfalow=JSON.parse(sessionStorage.getItem('company')).isFavorite
            this.token = localStorage.getItem('token');
            if (this.token) {
                this.getdetail();

                // 4.27
                // this.getCollData();
                // this.hasPdfPurchase();
            }
        });
        this.getdetail();
        this.updateSubLinkParent();
    },

    //页面销毁
    beforeDestroy() {
        // bus.$off('headLogFlg');
        window.removeEventListener('setItem', () => {
            this.token = localStorage.getItem('token');
        });

        // sessionStorage.removeItem('menuItem')
        // window.removeEventListener('setItem', this.listenMenuIndexChange);
    },
    destroyed() {
        bus.$off('toRightHeadObj');
        // bus.$off('handleShowLogin');
        localStorage.removeItem('menuItem');
    },
    watch: {
        token: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // this.getMenuList();
                }
                // this.loadig=false
            },
            immediate: true,
        },
    },
    methods: {
        tabshow(e) {
            if (this.isActive) {
                this.isActive = false;
            } else {
                this.isActive = true;
            }
        },
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);
                let nums = [];
                let nums1 = [];
                // this.Relationstable=
                // this.page.total=this.Relationstable.length
                if (this.$route.query.type == 'Person') {
                    this.images = res.data.attributes.image ? res.data.attributes.image.image_details : [];
                    this.zhuce = res.data.attributes.person?.date_details?.birth ? res.data.attributes.person.date_details?.birth[0].date : '';
                    this.person = res.data.attributes.person ? res.data.attributes.person : {};
                    this.watchlisrole = res.data.attributes.watchlist?.role_details ? res.data.attributes.watchlist.role_details : this.watchlisrole;
                    this.watchlisrole.primary = res.data.attributes?.watchlist?.role_details?.primary ? [res.data.attributes.watchlist?.role_details?.primary] : [];
                } else {
                    this.zhuce = res.data.attributes?.entity.date_details ? res.data.attributes?.entity?.date_details?.registration[0].date : '';
                    this.zhuce1 = res.data.attributes.entity.date_details ? res.data.attributes.entity.country_territory_details.registration[0] : '';
                    // this.Numbersable = res.data.attributes.entity.identification_details;

                    this.watchlistothertable = res.data.attributes?.list_reference?.other_official_lists ? res.data.attributes?.list_reference?.other_official_lists : [];
                }
                nums = res.data.attributes.person?.identification_details ? res.data.attributes.person?.identification_details : [];
                nums1 = res.data.attributes.watchlist?.identification_details ? res.data.attributes.watchlist?.identification_details : [];
                nums = nums.concat(nums1);
                // nums = Object.assign({},nums,nums1)
                this.parentTablenum1 = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [].concat(nums);
                this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
                this.str = res.data.attributes.watchlist?.comment_details?.list_comment ? res.data.attributes.watchlist.comment_details?.list_comment : '';
                this.sources = res.data.attributes?.watchlist?.sources ? res.data.attributes.watchlist.sources : [];
                // 制裁列表
                this.watchlisttable = res.data.attributes?.list_reference?.sanctions_lists ? res.data.attributes?.list_reference?.sanctions_lists : [];
                // Country/Territory Details
                this.lianshu = res.data.attributes?.watchlist?.country_territory_details ? res.data.attributes.watchlist.country_territory_details : {};
                // watchilsrt内容
                this.Wathchlist = res.data.attributes?.watchlist?.content_type_details ? res.data.attributes.watchlist.content_type_details : {};
                // 主称

                this.PrimaryNam = res.data.attributes.basic.name_details.primary_name;
                this.zhicaiTable1 = [res.data.attributes.basic.name_details.primary_name];
                // 别名
                this.Formerlyable2 = res.data.attributes.basic?.name_details.also_known_as ? res.data.attributes.basic?.name_details.also_known_as : [];
                // 前称
                this.Formerlyable1 = res.data.attributes.basic?.name_details?.formerly_known_as ? res.data.attributes.basic?.name_details?.formerly_known_as : [];
                this.Formerlyable = res.data.attributes.basic?.name_details?.also_known_as ? res.data.attributes.basic.name_details.also_known_as[0].associated_names : [];

                // 制裁名单
                // 档案注解
                // 关系
                this.timers = res.data.attributes?.watchlist?.last_reviewed_date ? res.data.attributes.watchlist.last_reviewed_date : '';
                this.parentTable1 = res.data.attributes?.relationship?.connection_details ? res.data.attributes.relationship.connection_details : [];
                this.tableList = res.data.attributes.relationship?.connection_details ? res.data.attributes.relationship?.connection_details : [];
            
                // 地址

                if (res.data.attributes?.basic?.address_details == undefined) {
                    res.data.attributes.basic = {};
                    res.data.attributes.basic.address_details = [];
                }
                if (res.data.attributes?.watchlist?.address_details == undefined) {
                    res.data.attributes.watchlist = {};
                    res.data.attributes.watchlist.address_details = [];
                }
                this.addressable = res.data.attributes?.basic?.address_details == undefined && res.data.attributes?.watchlist?.address_details == undefined ? [] : res.data.attributes.basic.address_details.concat(res.data.attributes.watchlist.address_details);
                // id
                // console.log(`this.addressable`, this.addressable);
                this.getMenuList();
            });
        },
        // 处理鼠标点击事件
        handleonmousedown(e) {
            // 处理登录+模块查看功能：
            const token = localStorage.getItem('token');
            if (!token) {
                //未登录 允许点击接口返回的指定power=true模块
                if (!(e.target?.id === 'fan_yi' || e.target?._prevClass === 'title flex_center subOn' || e.target?.innerText === 'FAQ')) {
                    //
                    // 弹出登录窗口
                    bus.$emit('handleShowLogin', true); //是否显示登录
                }
            }
        },
        updateSubLinkParent(val) {
            // this.subLinkActive=val
            if (val == undefined && this.$route.name == 'sanctionCompanySnapshot') {
                this.linkActive = 0;
                this.subLinkActive = '';
                return;
            }
            if (val == 0) {
                this.linkActive = 0;
                this.subLinkActive = '';
            } else if (val == 2) {
                this.linkActive = 2;
                this.subLinkActive = '2-0';
            } else if (val == 5) {
                this.linkActive = 5;
                this.subLinkActive = '5-4';
            } else if (val == 8) {
                this.linkActive = 5;
                this.subLinkActive = '';
            } else {
                this.linkActive = val;
                this.subLinkActive = '';
            }
            // this.getMenuList()
        },
        async getContentData() {
            // const companyCountry = this.$route.query.companyCountry;
            // const company = JSON.parse(sessionStorage.getItem('company'));
            this.$nextTick(() => {
                const base = JSON.parse(sessionStorage.getItem('base'));
                const combase = JSON.parse(localStorage.getItem('companyBase1'));
                this.companyBase = {
                    name: this.companyBase.name == '' ? '' : this.companyBase.name || base.base.name,
                    nameEn: this.companyBase.nameEn == '' ? '' : this.companyBase.nameEn || base.base.nameEn,
                    aaaId: this.$route.query.id3a,
                    companyCountry: base.base.countryFlag,
                    lastUpdate: base && base.base ? base.base.lastUpdate : '',
                    // || base && base.lables ? base.lables : ''
                    tags: this.companyBase.tags == [] ? [] : this.companyBase.tags,
                    // lableCode: base && base.lables ? base.lables.lableCode : '',
                    // lableDate: base && base.lables ? base.lables.lableDate : '',
                };
                // this.hasPdfPurchase();
            });
        },
        /**
         *用户购买报告 "type": 1 线下，2 线上, 13 Import/Export
         */
        async getOrdersAdd(type) {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;
            //1  :线上报告
            let params = {
                companyName: this.companyBase.nameEn, //必须
                aaaId: id3a, //必须
                companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    // this.getMenuList();
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    if (data.msg != ' Do not repeat the request') {
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            // duration: 5000,
                        });
                        let that = this;
                        let userinfo = JSON.parse(sessionStorage.getItem('userInfo')).userInfo;

                        let routeData = '';
                        setTimeout(function () {
                            if (userinfo.type == '3') {
                                routeData = that.$router.resolve({
                                    path: '/account/quota',
                                });
                            } else {
                                routeData = that.$router.resolve({
                                    path: '/account/addCredit',
                                });
                            }

                            window.open(routeData.href, '_blank');
                        }, 2000);

                        status = false;
                    }
                }
            });
            return status;
        },

        // 是否翻译
        // getIndexIsTranslate(data) {
        //   this.indexIsTranslate = data
        // },
        btnTranslate() {
            this.indexIsTranslate = !this.indexIsTranslate;
        },

        //折叠面板
        showSubMenu(el, index) {
            if (el.disBol == false) {
                el.disBol = true;
                return;
            }
            if (el.disBol == true) {
                el.disBol = false;
                return;
            }
        },
        //1.缓存第几个父级高亮,2.点击菜单
        handlFristMenu(index, obj, i) {
            this.update.updat = false;
            this.linkActive = index;
            this.subLinkActive = index + '-' + i;

            sessionStorage.setItem('menuItem', this.linkActive);
            //1.判断是否登录 login: true 需要登录
            if (obj.login && !this.token) {
                bus.$emit('handleShowLogin', true);
            } else {
                //2.判断是否需要扣点 power: false 无权限，需要扣点

                this.$router.push({ path: obj.url, query: { type: this.$route.query.type, id: this.$route.query.id, subsidiary: this.$route.query.subsidiary, name: this.$route.query.name, country: this.$route.query.country, gender: this.$route.query.gender, information: 'Search Sanctions' } });
            }
            this.powerToPage = JSON.stringify(obj.list);
        },
        async getMenuList() {

            var arrLsit = [
                {
                    id: '7001',
                    title: this.$route.query.type !='Person'?'Company Snapshot':'Person Snapshot',
                    login: false,
                    title2: 'sanctionCompanySnapshot',
                    power: true,
                    status: false,
                    url: '/sanctions/companysnapshot',
                    disBol: true,
                    result: [],
                    list: [
                        {
                            title: 'LEGAL STATUS',
                            sort: '1',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7002',
                            list: [],
                            deductionPoint: false,
                            pcode: '7001',
                        },
                        {
                            title: 'CURRENT OPERATIONS',
                            sort: '2',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7003',
                            list: [],
                            deductionPoint: false,
                            pcode: '7001',
                        },
                        {
                            title: 'PERFORMANCE',
                            sort: '3',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7004',
                            list: [],
                            deductionPoint: false,
                            pcode: '7001',
                        },
                        {
                            title: 'NEGATIVE RECORDS',
                            sort: '4',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7005',
                            list: [],
                            deductionPoint: false,
                            pcode: '7001',
                        },
                    ],
                },
                {
                    id: '7008',
                    title: 'Company Profile',
                    title2: 'sanctionCompanyProfile',
                    login: false,
                    power: true,
                    status:this.$route.query.type != 'Person' ? false : true,
                    url: '/sanctions/companyprofile',
                    disBol: true,
                    result: [
                        {
                            id: '7009',
                            name: 'ID Numbers',
                            login: false,
                            power: true,
                            list: [],
                            status: this.parentTablenum1.length > 0 ? false : true,
                            url: '/sanctions/IDNumbers',
                        },
                        {
                            id: '7080',
                            name: 'Relations',
                            login: false,
                            power: true,
                            list: [],
                            status: this.tableList.length > 0 ? false : true,
                            url: '/sanctions/Relations',
                        },
                    ],
                    list: [],
                },
                {
                    id: '7008',
                    title: 'ID Numbers',
                    title2: 'IDNumbers',
                    login: false,
                    power: true,
                    status:this.$route.query.type == 'Person' && this.parentTablenum1.length > 0 ? false : true,
                    url: '/sanctions/IDNumbers',
                    disBol: true,
                    result: [
                     
                    ],
                    list: [],
                },
                {
                    id: '7009',
                    title: 'Relations',
                    title2: 'seanctionRelations',
                    login: false,
                    power: true,
                    status:this.$route.query.type == 'Person' && this.tableList.length > 0 ? false : true,
                    url: '/sanctions/Relations',
                    disBol: true,
                    result: [
            
                    ],
                    list: [],
                },
                {
                    id: '7010',
                    title: 'Sanction List',
                    title2: 'Sanction List',
                    login: false,
                    power: true,
                    status: this.watchlisttable.length > 0 ? false : true,
                    url: '/sanctions/Sactionslist',
                    disBol: true,
                    result: [],
                    list: [
                        {
                            title: 'Export',
                            sort: '1',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7011',
                            list: [],
                            deductionPoint: false,
                            pcode: '7010',
                        },
                    ],
                },
                {
                    id: '7014',
                    title: 'Other Official List',
                    title2: 'OtherOfficalList',
                    login: false,
                    power: true,
                    status: this.watchlistothertable.length > 0 ? false : true,
                    url: '/sanctions/OtherOfficalList',
                    disBol: true,
                    result: [],
                    list: [
                        {
                            title: 'Export',
                            sort: '1',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7015',
                            list: [],
                            deductionPoint: false,
                            pcode: '7014',
                        },
                        {
                            title: 'echarts',
                            sort: '2',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7016',
                            list: [],
                            deductionPoint: false,
                            pcode: '7014',
                        },
                    ],
                },
                {
                    id: '7015',
                    title: 'Rouers',
                    title2: 'Rouers',
                    login: false,
                    power: true,
                    status: this.$route.query.type == 'Person'&& this.watchlisrole?.primary?.length > 0  ? false : true,
                    url: '/sanctions/Rouers',
                    disBol: true,
                    result: [
                    {
                            id: '7016',
                            name: 'Other Roles',
                            login: false,
                            power: true,
                            list: [],
                            status:this.$route.query.type == 'Person'&& this.watchlisrole?.other ? false : true,
                            url: '/sanctions/OtherRoles',
                        },
                        {
                            id: '7017',
                            name: 'History',
                            login: false,
                            power: true,
                            list: [],
                            status:this.$route.query.type == 'Person'&& this.watchlisrole?.previous ? false : true,
                            url: '/sanctions/History',
                        },
                    ],
                    list: [
                       
                    ],
                },
                {
                    id: '7016',
                    title: 'Name',
                    title2: 'Name',
                    login: false,
                    power: true,
                    status:  false,
                    url: '/sanctions/Name',
                    disBol: true,
                    result: [
                    {
                            id: '7016',
                            name: 'Place Of Birth',
                            login: false,
                            power: true,
                            list: [],
                            status:this.$route.query.type == 'Person'&& this.person?.places_of_birth ? false : true,
                            url: '/sanctions/Placeofbirth',
                        },
                        {
                            id: '7017',
                            name: 'Image',
                            login: false,
                            power: true,
                            list: [],
                            status:this.$route.query.type == 'Person'&& this.images ? false : true,
                            url: '/sanctions/Image',
                        },
                    ],
                    list: [
                       
                    ],
                },
                {
                    id: '7017',
                    title: 'Addresses',
                    title2: 'Addresses',
                    login: false,
                    power: true,
                    status:  this.addressable?.length > 0  ? false : true,
                    url: '/sanctions/Addresses',
                    disBol: true,
                    result: [
                   
                    ],
                    list: [
                       
                    ],
                },
                {
                    id: '7023',
                    title: 'Profile Notes',
                    title2: 'seanctionProfileNotes',
                    login: false,
                    power: true,
                    status: this.str.length > 0 || this.$route.query.type == 'Entity' ? false : true,
                    url: '/sanctions/ProfileNotes',
                    disBol: true,
                    result: [
                        // {
                        //     id: '7024',
                        //     name: 'Note Deltes',
                        //     login: false,
                        //     power: true,
                        //     list: [],
                        //     status: false,
                        //     url: '/sanctions/NoteDeltes',
                        // },
                    ],
                    list: [],
                },

                {
                    id: '7038',
                    title: 'Sources',
                    title2: 'Sources',
                    login: false,
                    power: true,
                    status: this.sources.length > 0 ? false : true,
                    url: '/sanctions/Sources',
                    disBol: true,
                    result: [],
                    list: [
                        {
                            title: 'Export',
                            sort: '1',
                            type: '2',
                            menuType: '7',
                            power: true,
                            status: false,
                            login: false,
                            code: '7046',
                            list: [],
                            deductionPoint: false,
                            pcode: '7038',
                        },
                    ],
                },
            ];

            this.menuTitleList = arrLsit;

            // this.subLinkActive=='0-' ? (this.powerToPag = JSON.stringify(this.menuTitleList[0].list)) : this.powerToPag;
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 170px;
    margin-bottom: 80px;
}
/* 头部 */
.flex_between_box {
    display: flex;
    justify-content: space-between;
}
.c_detail_top_right,
.c_detail_top_left {
    margin-top: 30px;
}
.c_d_title {
    width: 490px;
    font-size: 28px;
    font-family: 'Arial Bold';
    line-height: 40px;
    max-height: 74px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 20px;
}
.c_d_tag {
    width: 530px;
}
.c_d_tag .el-tag {
    height: 26px;
    line-height: 26px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #ff7600;
    font-size: 12px;
    color: #ff7600;
    margin: 0 4px 6px;
}
.last_date {
    line-height: 27px;
    font-size: 12px;
    color: #8497ab;
}
.btn_gray,
.btn_blue {
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
}
.btn_gray,
.btn_blue:disabled {
    color: #8497ab;
    background: #e7eaed;
}
.btn_gray {
    margin-right: 10px;
}
.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.c_d_buttom {
    margin-bottom: 40px;
}
.c_d_buttom /deep/ .el-popover__reference-wrapper .btn_blue {
    margin-right: 10px;
}
.c_d_buttom button + button {
    margin-left: 10px;
}
.c_d_icbtn {
    text-align: right;
}
.c_d_icbtn .el-button {
    width: 26px;
    height: 26px;
    background: #022955;
    border-radius: 13px;
    border: none;
    padding: 0;
}
.c_d_icbtn .el-button img {
    width: 14px;
}
.tool_add {
    background: #ff7600 !important;
}
.tool_tip {
    height: 34px;
    font-size: 14px;
    color: #022955;
    background: #ffffff;
    box-shadow: 0px 1px 4px 1px rgba(10, 33, 57, 0.2);
    border-radius: 8px;
    border: none !important;
}
.el-tooltip__popper[x-placement^='bottom'] {
    margin-top: 4px !important;
}
.tool_tip2 {
    width: 174px;
}
.tool_tip3 {
    width: 110px;
}

/* 菜单 */
.left_menu {
    padding: 20px 20px 20px 10px;
    align-self: flex-start;
}
.de_menu_list .menu_ic {
    height: 20px;
    margin-right: 10px;
}
.noitclick {
    pointer-events: none;
    /* color: #8497ab; */
    background: #e7eaed !important;
    cursor: no-drop !important;
    cursor: not-allowed !important;
    position: relative;
    z-index: 99999999;
    /* border-radius: 24px; */
}
.noitclickk {
    pointer-events: none;
    color: #8497ab;
    background: #e7eaed !important;
    /* cursor: no-drop; */
    cursor: not-allowed;
    /* border-radius: 24px; */
}
.de_menu_list .show_ic {
    width: 30px;
    /* height: 30px; */
    text-align: right;
}
.de_menu_list .show_ic img {
    width: 10px;
}
.de_menu_item {
    margin-bottom: 10px;
}
.de_menu_subitem_a {
    display: inline-block;
    color: #8497ab;
}
.de_menu_item_a {
    display: inline-block;
    /* color: #8497ab; */
}
.de_menu_item_a {
    font-size: 16px;
}
.de_menu_item_a .title {
    padding: 10px;
    cursor: pointer;
    color: #022955;
    position: relative;
}
.de_menu_subitem div {
    padding-left: 31px;
}
.de_menu_subitem_a {
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.de_menu_item_a .subOn {
    font-family: 'Arial Bold';
    color: #022955;
    cursor: pointer;
    /* background: #ebf2ff;
    border-radius: 14px; */
}
.de_menu_item_a.router-link-active .title,
.de_menu_subitem_a.router-link-active {
    background: #ebf2ff;
    border-radius: 14px;
    color: #022955;
}

/* 折叠面板 */
.sub-comments-leave-active,
.sub-comments-enter-active {
    transition: max-height 0.25s ease;
}
.sub-comments-enter,
.sub-comments-leave-to {
    max-height: 0;
}
.sub-comments-enter-to,
.sub-comments-leave {
    max-height: 600px;
}
.de_menu_sublist {
    will-change: height;
    overflow: hidden;
    /* cursor: not-allowed !important; */
}
.close,
.open {
    transform-origin: center;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
}
.open {
    background-image: url(../../assets/img/search/menu_ic2.png);
}
.close {
    background-image: url(../../assets/img/search/menu_ic1.png);
}
</style>
<style>
.pop_link {
    padding: 0 0 8px;
    border-radius: 8px;
    line-height: 42px;
    font-size: 16px;

    box-shadow: 0px -8px 20px 0px rgba(10, 33, 57, 0.24);
}
.compare_box li {
    text-align: center;
    margin-top: 4px;
    color: #022955;
    line-height: 42px;
    font-size: 16px;
}
.compare_box li:hover {
    background: rgba(18, 144, 201, 0.1);
    color: #1290c9;
    cursor: pointer;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
}
</style>
