
<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <!-- <div class="detail_cont_text" v-if="obj.title == 'Import & Export' && update.updat != true">• Import & export information is a paid feature, this will cost 2 units.</div> -->
                    <div class="detail_cont_text" v-if="(obj.title == 'Import & Export'|| obj.name == 'Import'|| obj.name == 'Export'|| obj.name == 'Supply Chain') && update.updat != true">• Import & export information is a paid feature, this will cost 2 units.</div>
                    <div class="detail_cont_text" v-else-if="obj.name == 'Balance Sheet' || obj.name == 'Profit & Loss' || obj.name == 'Ratio Analysis' || obj.name == 'Credit Assessment' || update.updat == true">
                        • Financial performance is a paid feature, this allows you to view all the available data including financial details and credit assessment on the company. The cost of this update is 10 units per company.<br> Subject company's financial performance data is often collected from various public data sources as well as local credit reporting agencies. This data is also calculated, modeled and forecasted based on market and industry research and a variety of available data. This data is for your reference only. <br>If the available online data is incomplete or out of date, an update for a fresh investigated business credit report will be automatically ordered via our local credit reporting agencies. It normally takes about 72 hours for an update report delivery. A business credit
                        report often contains details about the subject company's actual operations, financial performance, credit analysis and assessment.
                    </div>
                    <div class="detail_cont_text" v-else>• This is a paid feature, open it to view all the available online information about this company. This will cost 1 unit.</div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>

  <script>
export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
    },
    data() {
        return {
            //page: this.currentPage

            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() {},
        },
        isPagin: {
            get() {
                let status = sessionStorage.getItem('token') ? false : true;
                return status;
            },
            set() {},
        },
    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        //关闭弹窗
        closeDialog() {
            this.$emit('closeCloseDialog', false);
        },
        onSubmitkoudian() {
            this.newDialog = false;
            this.$emit('closeCloseDialog', false);
            //  this.$emit('closeCloseDialog1', true);
       
            if (this.update.updat) {
                this.$parent.getPDFFile(this.update.PDFFile);
            } else {
                let name = this.obj.title;
                let name1 = this.obj.name;
                this.$parent.refreshPowerStatus(name || name1).then(isPower => {
                    if (isPower) this.$router.push({ path: this.obj.url, query: { id3a: this.$route.query.id3a, companyCountry: this.$route.query.companyCountry, companyCountrydqs: this.$route.query.companyCountrydqs } });
                });
            }
        },
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
    },
};
</script>
  <style scoped>
.detail_cont {
    max-height: 85vh;
    overflow: auto;
    padding: 0px 20px 20px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
.dialog_normalsuo {
    width: 720px;
    background: #ffffff;
    border-radius: 20px;
    /* margin-top: 20vh !important; */
}
.dialog_normalsuo .el-dialog__body {
    padding: 30px;
}
</style>
  





